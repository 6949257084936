const defaultFrequencyCapping = {
  all: { day: 3, week: 10, month: 20 },
  email: { day: 3, week: 10, month: 20 },
  sms: { day: 3, week: 10, month: 20 },
  'mobile-push': { day: 3, week: 10, month: 20 },
  'web-push': { day: 3, week: 10, month: 20 },
  whatsApp: { day: 3, week: 10, month: 20 }
}

// default campaign throttling settings
const defaultCampaignThrottling = {
  email: 0,
  sms: 0,
  mobilePush: 0,
  webPush: 0,
  whatsApp: 0
}
const mappedFrequencyTableColName = {
  all: 'All Channels Combined',
  email: 'Email',
  sms: 'SMS',
  mobilePush: 'Push',
  webPush: 'Web Push',
  whatsApp: 'WhatsApp'
}

function getFrequencyCappingNameFromKey(key) {
  return mappedFrequencyTableColName[key] || null
}

export { defaultFrequencyCapping, defaultCampaignThrottling, getFrequencyCappingNameFromKey }
