<template>
    <div style="min-height:500px;" v-loading="loading" class="m-4">
      <div v-show="loaded">
        <div class="-mt-3 mb-4 text-right">
          <el-button type="primary" @click="saveChanges" :loading="savingBusy">Save changes</el-button>
        </div>
        <div class="border border-gray-200 overflow-hidden">
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="name" label="CHANNELS">
              <template #default="scope">{{ scope.row.name }}</template>
            </el-table-column>
            <el-table-column prop="name" label="MESSAGES PER MINUTES" width="250">
              <template #default="scope">
                <el-input @blur="onBlurInput" type="number" required v-model="scope.row.minute" />
              </template>
            </el-table-column>
            <el-table-column prop="address" label="" width="250">
            </el-table-column>
            <el-table-column prop="address" label="" width="250">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </template>
<script>
import { onMounted, reactive, toRefs } from 'vue'
import { getCampaignThrottling, saveCampaignThrottling } from '@/services/companySettingsService'
import { defaultCampaignThrottling, getFrequencyCappingNameFromKey } from '@/metadata/campaign_settings'
import { ElMessageBox, ElMessage } from 'element-plus'
export default {
  setup() {
    const state = reactive({
      loading: false,
      loaded: false,
      savingBusy: false,
      frequencyData: convertFrequencyToTableData(defaultCampaignThrottling),
      tableData: []
    })
    onMounted(() => {
      fetchData()
    })
    function convertFrequencyToTableData(frequencyData) {
      return Object.keys(frequencyData).reduce((arr, key) => {
        console.log('key', key)
        console.log('GetFrequencyCappingNameFromKey', getFrequencyCappingNameFromKey(key))
        arr.push({
          key: key,
          name: getFrequencyCappingNameFromKey(key),
          minute: frequencyData[key],
          ...frequencyData[key]
        })
        return arr
      }, [])
    }
    function tableDataToFrequency() {
      console.log('state.tableData', state.tableData)
      return state.tableData.reduce((obj, row) => {
        obj[row.key] = parseInt(row.minute)
        console.log('...', obj)
        return obj
      }, {})
    }
    async function fetchData() {
      try {
        state.loading = true
        const res = await getCampaignThrottling(['campaign-throttling'])
        const frequencyData = res.data
        console.log('res', res.data)
        console.log('frequencyData', frequencyData)
        state.tableData = convertFrequencyToTableData(frequencyData)
        console.log('state.tableData', state.tableData)
      } catch (_) {
      } finally {
        state.loading = false
        state.loaded = true
      }
    }
    async function saveChanges() {
      const payload = tableDataToFrequency()
      try {
        state.savingBusy = true
        console.log(payload)
        await saveCampaignThrottling(payload)
        ElMessage({
          showClose: true,
          type: 'success',
          message: 'Changes Saved.'
        })
      } catch (_) {
        ElMessageBox.alert("Something's not right! Please contact support.", 'Error', {
          confirmButtonText: 'OK'
        })
      } finally {
        state.savingBusy = false
      }
    }
    function onBlurInput(evt) {
      if (!evt.target.value) {
        evt.target.value = 0
      }
    }
    return {
      ...toRefs(state),
      saveChanges,
      onBlurInput
    }
  }
}
</script>
