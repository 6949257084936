import session from './session'
import { rootAPI } from './settings'

const fetchApiCredentials = () => {
  return session.get(rootAPI + 'settings/api-credentials')
}

const getSettings = params => {
  return session.post(rootAPI + 'settings/get', params)
}

const saveSettings = (params, settingsType) => {
  return session.post(rootAPI + 'settings/save/' + settingsType, params)
}

const getCampaignFrequencyCapping = payload => {
  return session.get(rootAPI + 'settings/get/campaign-frequency-capping', payload)
}

const saveCampaignFrequencyCapping = payload => {
  return session.post(rootAPI + 'settings/save/campaign-frequency-capping', payload)
}

// Get campaign throttling settings
const getCampaignThrottling = payload => {
  return session.get(rootAPI + 'settings/get/campaign-throttling', payload)
}

// Save campaign throttling settings
const saveCampaignThrottling = payload => {
  return session.post(rootAPI + 'settings/save/campaign-throttling', payload)
}

export { fetchApiCredentials, getSettings, saveSettings, getCampaignFrequencyCapping, saveCampaignFrequencyCapping, getCampaignThrottling, saveCampaignThrottling }
